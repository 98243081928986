import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import createStore from '../store/index.js'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login/',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/category_management/',
        name: 'category_management',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CategoryManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_category/',
        name: 'add_category',
        component: () => import('../views/AddCategoryView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_category/:category_id/',
        name: 'edit_category',
        component: () => import('../views/EditCategoryView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/product_management/',
        name: 'product_management',
        component: () => import('../views/ProductManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_product/',
        name: 'add_product',
        component: () => import('../views/AddProductView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_product/:product_id/',
        name: 'edit_product',
        component: () => import('../views/EditProductView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/product_image_management/:product_id/',
        name: 'product_image_management',
        component: () => import('../views/ProductImageManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_product_image/:product_id/',
        name: 'add_product_image',
        component: () => import('../views/AddProductImageView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/product_option_management/:product_id/',
        name: 'product_option_management',
        component: () => import('../views/ProductOptionManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_product_option/:product_id/',
        name: 'add_product_option',
        component: () => import('../views/AddProductOptionView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_product_option/:product_option_id/',
        name: 'edit_product_option',
        component: () => import('../views/EditProductOptionView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/campaign_management/',
        name: 'campaign_management',
        component: () => import('../views/CampaignManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_campaign/',
        name: 'add_campaign',
        component: () => import('../views/AddCampaignView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_campaign/:campaign_id/',
        name: 'edit_campaign',
        component: () => import('../views/EditCampaignView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupon_management/',
        name: 'coupon_management',
        component: () => import('../views/CouponManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_coupon/',
        name: 'add_coupon',
        component: () => import('../views/AddCouponView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_coupon/:coupon_id/',
        name: 'edit_coupon',
        component: () => import('../views/EditCouponView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/allowed_user_list_management/:coupon_id/',
        name: 'allowed_user_list_management',
        component: () => import('../views/AllowedUserListManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_allowed_user/:coupon_id/',
        name: 'add_allowed_user',
        component: () => import('../views/AddAllowedUserView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupon_usage_record_management/:coupon_id/',
        name: 'coupon_usage_record_management',
        component: () => import('../views/CouponUsageRecordManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/coupon_product_management/:coupon_id/',
        name: 'coupon_product_management',
        component: () => import('../views/CouponProductManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/order_management/',
        name: 'order_management',
        component: () => import('../views/OrderManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/order/:order_id/',
        name: 'order',
        component: () => import('../views/OrderView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user_management/',
        name: 'user_management',
        component: () => import('../views/UserManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/unit_management/',
        name: 'unit_management',
        component: () => import('../views/UnitManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_unit/',
        name: 'add_unit',
        component: () => import('../views/AddUnitView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_unit/:unit_id/',
        name: 'edit_unit',
        component: () => import('../views/EditUnitView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company_management/',
        name: 'company_management',
        component: () => import('../views/CompanyManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_company/',
        name: 'add_company',
        component: () => import('../views/AddCompanyView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_company/:company_id/',
        name: 'edit_company',
        component: () => import('../views/EditCompanyView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company_mail_sticker_a4/:company_id/',
        name: 'company_mail_sticker_a4',
        component: () => import('../views/CompanyMailStickerA4View.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company_mail_sticker_a5/:company_id/',
        name: 'company_mail_sticker_a5',
        component: () => import('../views/CompanyMailStickerA5View.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/commodity_management/',
        name: 'commodity_management',
        component: () => import('../views/CommodityManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_commodity/',
        name: 'add_commodity',
        component: () => import('../views/AddCommodityView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_commodity/:commodity_id/',
        name: 'edit_commodity',
        component: () => import('../views/EditCommodityView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/delivery_order_management/',
        name: 'delivery_order_management',
        component: () => import('../views/DeliveryOrderManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_delivery_order/',
        name: 'add_delivery_order',
        component: () => import('../views/AddDeliveryOrderView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_delivery_order/:delivery_order_id/',
        name: 'edit_delivery_order',
        component: () => import('../views/EditDeliveryOrderView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quotation_management/',
        name: 'quotation_management',
        component: () => import('../views/QuotationManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_quotation/',
        name: 'add_quotation',
        component: () => import('../views/AddQuotationView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_quotation/:quotation_id/',
        name: 'edit_quotation',
        component: () => import('../views/EditQuotationView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/quotation_commodity_management/',
        name: 'quotation_commodity_management',
        component: () => import('../views/QuotationCommodityManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_quotation_commodity/',
        name: 'add_quotation_commodity',
        component: () => import('../views/AddQuotationCommodityView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_quotation_commodity/:quotation_commodity_id/',
        name: 'edit_quotation_commodity',
        component: () => import('../views/EditQuotationCommodityView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/invoice_detail_management/',
        name: 'invoice_detail_management',
        component: () => import('../views/InvoiceDetailManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_invoice_detail/',
        name: 'add_invoice_detail',
        component: () => import('../views/AddInvoiceDetailView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_invoice_detail/:invoice_detail_id/',
        name: 'edit_invoice_detail',
        component: () => import('../views/EditInvoiceDetailView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/sales_report/',
        name: 'sales_report',
        component: () => import('../views/SalesReportView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/monthly_sales_amount_report/',
        name: 'monthly_sales_amount_report',
        component: () => import('../views/MonthlySalesAmountReportView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/annual_sales_amount_report/',
        name: 'annual_sales_amount_report',
        component: () => import('../views/AnnualSalesAmountReportView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/commodity_monthly_sales_amount_report/',
        name: 'commodity_monthly_sales_amount_report',
        component: () => import('../views/CommodityMonthlySalesAmountReportView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/commodity_annual_sales_amount_report/',
        name: 'commodity_annual_sales_amount_report',
        component: () => import('../views/CommodityAnnualSalesAmountReportView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company_purchase_report/',
        name: 'company_purchase_report',
        component: () => import('../views/CompanyPurchaseReportView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/generate_customized_mail_sticker_a5/',
        name: 'generate_customized_mail_sticker_a5',
        component: () => import('../views/GenerateCustomizedMailStickerA5View.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/customized_mail_sticker_a5/',
        name: 'customized_mail_sticker_a5',
        component: () => import('../views/CustomizedMailStickerA5View.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/printable_delivery_order_a4/:delivery_order_id/',
        name: 'printable_delivery_order_a4',
        component: () => import('../views/PrintableDeliveryOrderA4View.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/printable_delivery_order_a5/:delivery_order_id/',
        name: 'printable_delivery_order_a5',
        component: () => import('../views/PrintableDeliveryOrderA5View.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/machine_management/',
        name: 'machine_management',
        component: () => import('../views/MachineManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_machine/',
        name: 'add_machine',
        component: () => import('../views/AddMachineView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_machine/:machine_id/',
        name: 'edit_machine',
        component: () => import('../views/EditMachineView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/machine_record_management/',
        name: 'machine_record_management',
        component: () => import('../views/MachineRecordManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_machine_record/',
        name: 'add_machine_record',
        component: () => import('../views/AddMachineRecordView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_machine_record/:machine_record_id/',
        name: 'edit_machine_record',
        component: () => import('../views/EditMachineRecordView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounting_first_grade_management/',
        name: 'accounting_first_grade_management',
        component: () => import('../views/AccountingFirstGradeManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_accounting_first_grade/',
        name: 'add_accounting_first_grade',
        component: () => import('../views/AddAccountingFirstGradeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_accounting_first_grade/:accounting_first_grade_id/',
        name: 'edit_accounting_first_grade',
        component: () => import('../views/EditAccountingFirstGradeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounting_second_grade_management/',
        name: 'accounting_second_grade_management',
        component: () => import('../views/AccountingSecondGradeManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_accounting_second_grade/',
        name: 'add_accounting_second_grade',
        component: () => import('../views/AddAccountingSecondGradeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_accounting_second_grade/:accounting_second_grade_id/',
        name: 'edit_accounting_second_grade',
        component: () => import('../views/EditAccountingSecondGradeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounting_third_grade_management/',
        name: 'accounting_third_grade_management',
        component: () => import('../views/AccountingThirdGradeManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_accounting_third_grade/',
        name: 'add_accounting_third_grade',
        component: () => import('../views/AddAccountingThirdGradeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_accounting_third_grade/:accounting_third_grade_id/',
        name: 'edit_accounting_third_grade',
        component: () => import('../views/EditAccountingThirdGradeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounting_document_management/',
        name: 'accounting_document_management',
        component: () => import('../views/AccountingDocumentManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/add_accounting_document/',
        name: 'add_accounting_document',
        component: () => import('../views/AddAccountingDocumentView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/edit_accounting_document/:accounting_document_id/',
        name: 'edit_accounting_document',
        component: () => import('../views/EditAccountingDocumentView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/internal_accounting_subsidiary_ledger/',
        name: 'internal_accounting_subsidiary_ledger',
        component: () => import('../views/InternalAccountingSubsidiaryLedgerView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/internal_accounting_income_statement/',
        name: 'internal_accounting_income_statement',
        component: () => import('../views/InternalAccountingIncomeStatementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/internal_accounting_balance_sheet/',
        name: 'internal_accounting_balance_sheet',
        component: () => import('../views/InternalAccountingBalanceSheetView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/external_accounting_subsidiary_ledger/',
        name: 'external_accounting_subsidiary_ledger',
        component: () => import('../views/ExternalAccountingSubsidiaryLedgerView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/external_accounting_income_statement/',
        name: 'external_accounting_income_statement',
        component: () => import('../views/ExternalAccountingIncomeStatementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/external_accounting_balance_sheet/',
        name: 'external_accounting_balance_sheet',
        component: () => import('../views/ExternalAccountingBalanceSheetView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/email_track_management/',
        name: 'email_track_management',
        component: () => import('../views/EmailTrackManagementView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/404/',
        name: '404',
        component: () => import('../views/Page404View'),
        hidden: true
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404/',
        hidden: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!createStore.getters.isLoggedIn) {
            next({ name: 'login' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
