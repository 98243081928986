import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    install: (app) => {
        app.config.globalProperties.$localDate = (time_str) => {
            return time_str ? dayjs(time_str)
                .tz('Asia/Taipei')
                .format('YYYY/MM/DD') : ''
        }
        app.config.globalProperties.$localTime = (time_str) => {
            return time_str ? dayjs(time_str)
                .tz('Asia/Taipei')
                .format('YYYY/MM/DD HH:mm:ss') : ''
        }
        app.config.globalProperties.$iso8601Time = (time_str) => {
            return dayjs(time_str, 'YYYY-MM-DD HH:mm:ss', true).utc().format()
        }
    }
}