export default {
    install: (app) => {
        app.config.globalProperties.$api_host = 'https://nestor.com.tw'
        // app.config.globalProperties.$api_host = 'https://127.0.0.1:8000'
        app.config.globalProperties.$cloudfront_host = 'https://d1zyhmlibzhv4g.cloudfront.net'
        app.config.globalProperties.$county_list = [
            '臺北市',
            '基隆市',
            '新北市',
            '桃園市',
            '新竹縣',
            '新竹市',
            '苗栗縣',
            '臺中市',
            '彰化縣',
            '南投縣',
            '雲林縣',
            '嘉義縣',
            '嘉義市',
            '臺南市',
            '高雄市',
            '屏東縣',
            '宜蘭縣',
            '花蓮縣',
            '臺東縣',
            '澎湖縣',
            '金門縣',
            '連江縣',
        ]
        app.config.globalProperties.$zip_codes = [{
            'id': '100臺北市中正區',
            'zip_code': 100,
            'county': '臺北市',
            'city': '中正區'
        }, {
            'id': '103臺北市大同區',
            'zip_code': 103,
            'county': '臺北市',
            'city': '大同區'
        }, {
            'id': '104臺北市中山區',
            'zip_code': 104,
            'county': '臺北市',
            'city': '中山區'
        }, {
            'id': '105臺北市松山區',
            'zip_code': 105,
            'county': '臺北市',
            'city': '松山區'
        }, {
            'id': '106臺北市大安區',
            'zip_code': 106,
            'county': '臺北市',
            'city': '大安區'
        }, {
            'id': '108臺北市萬華區',
            'zip_code': 108,
            'county': '臺北市',
            'city': '萬華區'
        }, {
            'id': '110臺北市信義區',
            'zip_code': 110,
            'county': '臺北市',
            'city': '信義區'
        }, {
            'id': '111臺北市士林區',
            'zip_code': 111,
            'county': '臺北市',
            'city': '士林區'
        }, {
            'id': '112臺北市北投區',
            'zip_code': 112,
            'county': '臺北市',
            'city': '北投區'
        }, {
            'id': '114臺北市內湖區',
            'zip_code': 114,
            'county': '臺北市',
            'city': '內湖區'
        }, {
            'id': '115臺北市南港區',
            'zip_code': 115,
            'county': '臺北市',
            'city': '南港區'
        }, {
            'id': '116臺北市文山區',
            'zip_code': 116,
            'county': '臺北市',
            'city': '文山區'
        }, {
            'id': '200基隆市仁愛區',
            'zip_code': 200,
            'county': '基隆市',
            'city': '仁愛區'
        }, {
            'id': '201基隆市信義區',
            'zip_code': 201,
            'county': '基隆市',
            'city': '信義區'
        }, {
            'id': '202基隆市中正區',
            'zip_code': 202,
            'county': '基隆市',
            'city': '中正區'
        }, {
            'id': '203基隆市中山區',
            'zip_code': 203,
            'county': '基隆市',
            'city': '中山區'
        }, {
            'id': '204基隆市安樂區',
            'zip_code': 204,
            'county': '基隆市',
            'city': '安樂區'
        }, {
            'id': '205基隆市暖暖區',
            'zip_code': 205,
            'county': '基隆市',
            'city': '暖暖區'
        }, {
            'id': '206基隆市七堵區',
            'zip_code': 206,
            'county': '基隆市',
            'city': '七堵區'
        }, {
            'id': '207新北市萬里區',
            'zip_code': 207,
            'county': '新北市',
            'city': '萬里區'
        }, {
            'id': '208新北市金山區',
            'zip_code': 208,
            'county': '新北市',
            'city': '金山區'
        }, {
            'id': '220新北市板橋區',
            'zip_code': 220,
            'county': '新北市',
            'city': '板橋區'
        }, {
            'id': '221新北市汐止區',
            'zip_code': 221,
            'county': '新北市',
            'city': '汐止區'
        }, {
            'id': '222新北市深坑區',
            'zip_code': 222,
            'county': '新北市',
            'city': '深坑區'
        }, {
            'id': '223新北市石碇區',
            'zip_code': 223,
            'county': '新北市',
            'city': '石碇區'
        }, {
            'id': '224新北市瑞芳區',
            'zip_code': 224,
            'county': '新北市',
            'city': '瑞芳區'
        }, {
            'id': '226新北市平溪區',
            'zip_code': 226,
            'county': '新北市',
            'city': '平溪區'
        }, {
            'id': '227新北市雙溪區',
            'zip_code': 227,
            'county': '新北市',
            'city': '雙溪區'
        }, {
            'id': '228新北市貢寮區',
            'zip_code': 228,
            'county': '新北市',
            'city': '貢寮區'
        }, {
            'id': '231新北市新店區',
            'zip_code': 231,
            'county': '新北市',
            'city': '新店區'
        }, {
            'id': '232新北市坪林區',
            'zip_code': 232,
            'county': '新北市',
            'city': '坪林區'
        }, {
            'id': '233新北市烏來區',
            'zip_code': 233,
            'county': '新北市',
            'city': '烏來區'
        }, {
            'id': '234新北市永和區',
            'zip_code': 234,
            'county': '新北市',
            'city': '永和區'
        }, {
            'id': '235新北市中和區',
            'zip_code': 235,
            'county': '新北市',
            'city': '中和區'
        }, {
            'id': '236新北市土城區',
            'zip_code': 236,
            'county': '新北市',
            'city': '土城區'
        }, {
            'id': '237新北市三峽區',
            'zip_code': 237,
            'county': '新北市',
            'city': '三峽區'
        }, {
            'id': '238新北市樹林區',
            'zip_code': 238,
            'county': '新北市',
            'city': '樹林區'
        }, {
            'id': '239新北市鶯歌區',
            'zip_code': 239,
            'county': '新北市',
            'city': '鶯歌區'
        }, {
            'id': '241新北市三重區',
            'zip_code': 241,
            'county': '新北市',
            'city': '三重區'
        }, {
            'id': '242新北市新莊區',
            'zip_code': 242,
            'county': '新北市',
            'city': '新莊區'
        }, {
            'id': '243新北市泰山區',
            'zip_code': 243,
            'county': '新北市',
            'city': '泰山區'
        }, {
            'id': '244新北市林口區',
            'zip_code': 244,
            'county': '新北市',
            'city': '林口區'
        }, {
            'id': '247新北市蘆洲區',
            'zip_code': 247,
            'county': '新北市',
            'city': '蘆洲區'
        }, {
            'id': '248新北市五股區',
            'zip_code': 248,
            'county': '新北市',
            'city': '五股區'
        }, {
            'id': '249新北市八里區',
            'zip_code': 249,
            'county': '新北市',
            'city': '八里區'
        }, {
            'id': '251新北市淡水區',
            'zip_code': 251,
            'county': '新北市',
            'city': '淡水區'
        }, {
            'id': '252新北市三芝區',
            'zip_code': 252,
            'county': '新北市',
            'city': '三芝區'
        }, {
            'id': '253新北市石門區',
            'zip_code': 253,
            'county': '新北市',
            'city': '石門區'
        }, {
            'id': '260宜蘭縣宜蘭市',
            'zip_code': 260,
            'county': '宜蘭縣',
            'city': '宜蘭市'
        }, {
            'id': '261宜蘭縣頭城鎮',
            'zip_code': 261,
            'county': '宜蘭縣',
            'city': '頭城鎮'
        }, {
            'id': '262宜蘭縣礁溪鄉',
            'zip_code': 262,
            'county': '宜蘭縣',
            'city': '礁溪鄉'
        }, {
            'id': '263宜蘭縣壯圍鄉',
            'zip_code': 263,
            'county': '宜蘭縣',
            'city': '壯圍鄉'
        }, {
            'id': '264宜蘭縣員山鄉',
            'zip_code': 264,
            'county': '宜蘭縣',
            'city': '員山鄉'
        }, {
            'id': '265宜蘭縣羅東鎮',
            'zip_code': 265,
            'county': '宜蘭縣',
            'city': '羅東鎮'
        }, {
            'id': '266宜蘭縣三星鄉',
            'zip_code': 266,
            'county': '宜蘭縣',
            'city': '三星鄉'
        }, {
            'id': '267宜蘭縣大同鄉',
            'zip_code': 267,
            'county': '宜蘭縣',
            'city': '大同鄉'
        }, {
            'id': '268宜蘭縣五結鄉',
            'zip_code': 268,
            'county': '宜蘭縣',
            'city': '五結鄉'
        }, {
            'id': '269宜蘭縣冬山鄉',
            'zip_code': 269,
            'county': '宜蘭縣',
            'city': '冬山鄉'
        }, {
            'id': '270宜蘭縣蘇澳鎮',
            'zip_code': 270,
            'county': '宜蘭縣',
            'city': '蘇澳鎮'
        }, {
            'id': '272宜蘭縣南澳鄉',
            'zip_code': 272,
            'county': '宜蘭縣',
            'city': '南澳鄉'
        }, {
            'id': '290宜蘭縣釣魚臺列嶼',
            'zip_code': 290,
            'county': '宜蘭縣',
            'city': '釣魚臺列嶼'
        }, {
            'id': '300新竹市東區',
            'zip_code': 300,
            'county': '新竹市',
            'city': '東區'
        }, {
            'id': '300新竹市北區',
            'zip_code': 300,
            'county': '新竹市',
            'city': '北區'
        }, {
            'id': '300新竹市香山區',
            'zip_code': 300,
            'county': '新竹市',
            'city': '香山區'
        }, {
            'id': '302新竹縣竹北市',
            'zip_code': 302,
            'county': '新竹縣',
            'city': '竹北市'
        }, {
            'id': '303新竹縣湖口鄉',
            'zip_code': 303,
            'county': '新竹縣',
            'city': '湖口鄉'
        }, {
            'id': '304新竹縣新豐鄉',
            'zip_code': 304,
            'county': '新竹縣',
            'city': '新豐鄉'
        }, {
            'id': '305新竹縣新埔鎮',
            'zip_code': 305,
            'county': '新竹縣',
            'city': '新埔鎮'
        }, {
            'id': '306新竹縣關西鎮',
            'zip_code': 306,
            'county': '新竹縣',
            'city': '關西鎮'
        }, {
            'id': '307新竹縣芎林鄉',
            'zip_code': 307,
            'county': '新竹縣',
            'city': '芎林鄉'
        }, {
            'id': '308新竹縣寶山鄉',
            'zip_code': 308,
            'county': '新竹縣',
            'city': '寶山鄉'
        }, {
            'id': '310新竹縣竹東鎮',
            'zip_code': 310,
            'county': '新竹縣',
            'city': '竹東鎮'
        }, {
            'id': '311新竹縣五峰鄉',
            'zip_code': 311,
            'county': '新竹縣',
            'city': '五峰鄉'
        }, {
            'id': '312新竹縣橫山鄉',
            'zip_code': 312,
            'county': '新竹縣',
            'city': '橫山鄉'
        }, {
            'id': '313新竹縣尖石鄉',
            'zip_code': 313,
            'county': '新竹縣',
            'city': '尖石鄉'
        }, {
            'id': '314新竹縣北埔鄉',
            'zip_code': 314,
            'county': '新竹縣',
            'city': '北埔鄉'
        }, {
            'id': '315新竹縣峨眉鄉',
            'zip_code': 315,
            'county': '新竹縣',
            'city': '峨眉鄉'
        }, {
            'id': '320桃園市中壢區',
            'zip_code': 320,
            'county': '桃園市',
            'city': '中壢區'
        }, {
            'id': '324桃園市平鎮區',
            'zip_code': 324,
            'county': '桃園市',
            'city': '平鎮區'
        }, {
            'id': '325桃園市龍潭區',
            'zip_code': 325,
            'county': '桃園市',
            'city': '龍潭區'
        }, {
            'id': '326桃園市楊梅區',
            'zip_code': 326,
            'county': '桃園市',
            'city': '楊梅區'
        }, {
            'id': '327桃園市新屋區',
            'zip_code': 327,
            'county': '桃園市',
            'city': '新屋區'
        }, {
            'id': '328桃園市觀音區',
            'zip_code': 328,
            'county': '桃園市',
            'city': '觀音區'
        }, {
            'id': '330桃園市桃園區',
            'zip_code': 330,
            'county': '桃園市',
            'city': '桃園區'
        }, {
            'id': '333桃園市龜山區',
            'zip_code': 333,
            'county': '桃園市',
            'city': '龜山區'
        }, {
            'id': '334桃園市八德區',
            'zip_code': 334,
            'county': '桃園市',
            'city': '八德區'
        }, {
            'id': '335桃園市大溪區',
            'zip_code': 335,
            'county': '桃園市',
            'city': '大溪區'
        }, {
            'id': '336桃園市復興區',
            'zip_code': 336,
            'county': '桃園市',
            'city': '復興區'
        }, {
            'id': '337桃園市大園區',
            'zip_code': 337,
            'county': '桃園市',
            'city': '大園區'
        }, {
            'id': '338桃園市蘆竹區',
            'zip_code': 338,
            'county': '桃園市',
            'city': '蘆竹區'
        }, {
            'id': '350苗栗縣竹南鎮',
            'zip_code': 350,
            'county': '苗栗縣',
            'city': '竹南鎮'
        }, {
            'id': '351苗栗縣頭份市',
            'zip_code': 351,
            'county': '苗栗縣',
            'city': '頭份市'
        }, {
            'id': '352苗栗縣三灣鄉',
            'zip_code': 352,
            'county': '苗栗縣',
            'city': '三灣鄉'
        }, {
            'id': '353苗栗縣南庄鄉',
            'zip_code': 353,
            'county': '苗栗縣',
            'city': '南庄鄉'
        }, {
            'id': '354苗栗縣獅潭鄉',
            'zip_code': 354,
            'county': '苗栗縣',
            'city': '獅潭鄉'
        }, {
            'id': '356苗栗縣後龍鎮',
            'zip_code': 356,
            'county': '苗栗縣',
            'city': '後龍鎮'
        }, {
            'id': '357苗栗縣通霄鎮',
            'zip_code': 357,
            'county': '苗栗縣',
            'city': '通霄鎮'
        }, {
            'id': '358苗栗縣苑裡鎮',
            'zip_code': 358,
            'county': '苗栗縣',
            'city': '苑裡鎮'
        }, {
            'id': '360苗栗縣苗栗市',
            'zip_code': 360,
            'county': '苗栗縣',
            'city': '苗栗市'
        }, {
            'id': '361苗栗縣造橋鄉',
            'zip_code': 361,
            'county': '苗栗縣',
            'city': '造橋鄉'
        }, {
            'id': '362苗栗縣頭屋鄉',
            'zip_code': 362,
            'county': '苗栗縣',
            'city': '頭屋鄉'
        }, {
            'id': '363苗栗縣公館鄉',
            'zip_code': 363,
            'county': '苗栗縣',
            'city': '公館鄉'
        }, {
            'id': '364苗栗縣大湖鄉',
            'zip_code': 364,
            'county': '苗栗縣',
            'city': '大湖鄉'
        }, {
            'id': '365苗栗縣泰安鄉',
            'zip_code': 365,
            'county': '苗栗縣',
            'city': '泰安鄉'
        }, {
            'id': '366苗栗縣銅鑼鄉',
            'zip_code': 366,
            'county': '苗栗縣',
            'city': '銅鑼鄉'
        }, {
            'id': '367苗栗縣三義鄉',
            'zip_code': 367,
            'county': '苗栗縣',
            'city': '三義鄉'
        }, {
            'id': '368苗栗縣西湖鄉',
            'zip_code': 368,
            'county': '苗栗縣',
            'city': '西湖鄉'
        }, {
            'id': '369苗栗縣卓蘭鎮',
            'zip_code': 369,
            'county': '苗栗縣',
            'city': '卓蘭鎮'
        }, {
            'id': '400臺中市中區',
            'zip_code': 400,
            'county': '臺中市',
            'city': '中區'
        }, {
            'id': '401臺中市東區',
            'zip_code': 401,
            'county': '臺中市',
            'city': '東區'
        }, {
            'id': '402臺中市南區',
            'zip_code': 402,
            'county': '臺中市',
            'city': '南區'
        }, {
            'id': '403臺中市西區',
            'zip_code': 403,
            'county': '臺中市',
            'city': '西區'
        }, {
            'id': '404臺中市北區',
            'zip_code': 404,
            'county': '臺中市',
            'city': '北區'
        }, {
            'id': '406臺中市北屯區',
            'zip_code': 406,
            'county': '臺中市',
            'city': '北屯區'
        }, {
            'id': '407臺中市西屯區',
            'zip_code': 407,
            'county': '臺中市',
            'city': '西屯區'
        }, {
            'id': '408臺中市南屯區',
            'zip_code': 408,
            'county': '臺中市',
            'city': '南屯區'
        }, {
            'id': '411臺中市太平區',
            'zip_code': 411,
            'county': '臺中市',
            'city': '太平區'
        }, {
            'id': '412臺中市大里區',
            'zip_code': 412,
            'county': '臺中市',
            'city': '大里區'
        }, {
            'id': '413臺中市霧峰區',
            'zip_code': 413,
            'county': '臺中市',
            'city': '霧峰區'
        }, {
            'id': '414臺中市烏日區',
            'zip_code': 414,
            'county': '臺中市',
            'city': '烏日區'
        }, {
            'id': '420臺中市豐原區',
            'zip_code': 420,
            'county': '臺中市',
            'city': '豐原區'
        }, {
            'id': '421臺中市后里區',
            'zip_code': 421,
            'county': '臺中市',
            'city': '后里區'
        }, {
            'id': '422臺中市石岡區',
            'zip_code': 422,
            'county': '臺中市',
            'city': '石岡區'
        }, {
            'id': '423臺中市東勢區',
            'zip_code': 423,
            'county': '臺中市',
            'city': '東勢區'
        }, {
            'id': '424臺中市和平區',
            'zip_code': 424,
            'county': '臺中市',
            'city': '和平區'
        }, {
            'id': '426臺中市新社區',
            'zip_code': 426,
            'county': '臺中市',
            'city': '新社區'
        }, {
            'id': '427臺中市潭子區',
            'zip_code': 427,
            'county': '臺中市',
            'city': '潭子區'
        }, {
            'id': '428臺中市大雅區',
            'zip_code': 428,
            'county': '臺中市',
            'city': '大雅區'
        }, {
            'id': '429臺中市神岡區',
            'zip_code': 429,
            'county': '臺中市',
            'city': '神岡區'
        }, {
            'id': '432臺中市大肚區',
            'zip_code': 432,
            'county': '臺中市',
            'city': '大肚區'
        }, {
            'id': '433臺中市沙鹿區',
            'zip_code': 433,
            'county': '臺中市',
            'city': '沙鹿區'
        }, {
            'id': '434臺中市龍井區',
            'zip_code': 434,
            'county': '臺中市',
            'city': '龍井區'
        }, {
            'id': '435臺中市梧棲區',
            'zip_code': 435,
            'county': '臺中市',
            'city': '梧棲區'
        }, {
            'id': '436臺中市清水區',
            'zip_code': 436,
            'county': '臺中市',
            'city': '清水區'
        }, {
            'id': '437臺中市大甲區',
            'zip_code': 437,
            'county': '臺中市',
            'city': '大甲區'
        }, {
            'id': '438臺中市外埔區',
            'zip_code': 438,
            'county': '臺中市',
            'city': '外埔區'
        }, {
            'id': '439臺中市大安區',
            'zip_code': 439,
            'county': '臺中市',
            'city': '大安區'
        }, {
            'id': '500彰化縣彰化市',
            'zip_code': 500,
            'county': '彰化縣',
            'city': '彰化市'
        }, {
            'id': '502彰化縣芬園鄉',
            'zip_code': 502,
            'county': '彰化縣',
            'city': '芬園鄉'
        }, {
            'id': '503彰化縣花壇鄉',
            'zip_code': 503,
            'county': '彰化縣',
            'city': '花壇鄉'
        }, {
            'id': '504彰化縣秀水鄉',
            'zip_code': 504,
            'county': '彰化縣',
            'city': '秀水鄉'
        }, {
            'id': '505彰化縣鹿港鎮',
            'zip_code': 505,
            'county': '彰化縣',
            'city': '鹿港鎮'
        }, {
            'id': '506彰化縣福興鄉',
            'zip_code': 506,
            'county': '彰化縣',
            'city': '福興鄉'
        }, {
            'id': '507彰化縣線西鄉',
            'zip_code': 507,
            'county': '彰化縣',
            'city': '線西鄉'
        }, {
            'id': '508彰化縣和美鎮',
            'zip_code': 508,
            'county': '彰化縣',
            'city': '和美鎮'
        }, {
            'id': '509彰化縣伸港鄉',
            'zip_code': 509,
            'county': '彰化縣',
            'city': '伸港鄉'
        }, {
            'id': '510彰化縣員林市',
            'zip_code': 510,
            'county': '彰化縣',
            'city': '員林市'
        }, {
            'id': '511彰化縣社頭鄉',
            'zip_code': 511,
            'county': '彰化縣',
            'city': '社頭鄉'
        }, {
            'id': '512彰化縣永靖鄉',
            'zip_code': 512,
            'county': '彰化縣',
            'city': '永靖鄉'
        }, {
            'id': '513彰化縣埔心鄉',
            'zip_code': 513,
            'county': '彰化縣',
            'city': '埔心鄉'
        }, {
            'id': '514彰化縣溪湖鎮',
            'zip_code': 514,
            'county': '彰化縣',
            'city': '溪湖鎮'
        }, {
            'id': '515彰化縣大村鄉',
            'zip_code': 515,
            'county': '彰化縣',
            'city': '大村鄉'
        }, {
            'id': '516彰化縣埔鹽鄉',
            'zip_code': 516,
            'county': '彰化縣',
            'city': '埔鹽鄉'
        }, {
            'id': '520彰化縣田中鎮',
            'zip_code': 520,
            'county': '彰化縣',
            'city': '田中鎮'
        }, {
            'id': '521彰化縣北斗鎮',
            'zip_code': 521,
            'county': '彰化縣',
            'city': '北斗鎮'
        }, {
            'id': '522彰化縣田尾鄉',
            'zip_code': 522,
            'county': '彰化縣',
            'city': '田尾鄉'
        }, {
            'id': '523彰化縣埤頭鄉',
            'zip_code': 523,
            'county': '彰化縣',
            'city': '埤頭鄉'
        }, {
            'id': '524彰化縣溪州鄉',
            'zip_code': 524,
            'county': '彰化縣',
            'city': '溪州鄉'
        }, {
            'id': '525彰化縣竹塘鄉',
            'zip_code': 525,
            'county': '彰化縣',
            'city': '竹塘鄉'
        }, {
            'id': '526彰化縣二林鎮',
            'zip_code': 526,
            'county': '彰化縣',
            'city': '二林鎮'
        }, {
            'id': '527彰化縣大城鄉',
            'zip_code': 527,
            'county': '彰化縣',
            'city': '大城鄉'
        }, {
            'id': '528彰化縣芳苑鄉',
            'zip_code': 528,
            'county': '彰化縣',
            'city': '芳苑鄉'
        }, {
            'id': '530彰化縣二水鄉',
            'zip_code': 530,
            'county': '彰化縣',
            'city': '二水鄉'
        }, {
            'id': '540南投縣南投市',
            'zip_code': 540,
            'county': '南投縣',
            'city': '南投市'
        }, {
            'id': '541南投縣中寮鄉',
            'zip_code': 541,
            'county': '南投縣',
            'city': '中寮鄉'
        }, {
            'id': '542南投縣草屯鎮',
            'zip_code': 542,
            'county': '南投縣',
            'city': '草屯鎮'
        }, {
            'id': '544南投縣國姓鄉',
            'zip_code': 544,
            'county': '南投縣',
            'city': '國姓鄉'
        }, {
            'id': '545南投縣埔里鎮',
            'zip_code': 545,
            'county': '南投縣',
            'city': '埔里鎮'
        }, {
            'id': '546南投縣仁愛鄉',
            'zip_code': 546,
            'county': '南投縣',
            'city': '仁愛鄉'
        }, {
            'id': '551南投縣名間鄉',
            'zip_code': 551,
            'county': '南投縣',
            'city': '名間鄉'
        }, {
            'id': '552南投縣集集鎮',
            'zip_code': 552,
            'county': '南投縣',
            'city': '集集鎮'
        }, {
            'id': '553南投縣水里鄉',
            'zip_code': 553,
            'county': '南投縣',
            'city': '水里鄉'
        }, {
            'id': '555南投縣魚池鄉',
            'zip_code': 555,
            'county': '南投縣',
            'city': '魚池鄉'
        }, {
            'id': '556南投縣信義鄉',
            'zip_code': 556,
            'county': '南投縣',
            'city': '信義鄉'
        }, {
            'id': '557南投縣竹山鎮',
            'zip_code': 557,
            'county': '南投縣',
            'city': '竹山鎮'
        }, {
            'id': '558南投縣鹿谷鄉',
            'zip_code': 558,
            'county': '南投縣',
            'city': '鹿谷鄉'
        }, {
            'id': '600嘉義市東區',
            'zip_code': 600,
            'county': '嘉義市',
            'city': '東區'
        }, {
            'id': '600嘉義市西區',
            'zip_code': 600,
            'county': '嘉義市',
            'city': '西區'
        }, {
            'id': '602嘉義縣番路鄉',
            'zip_code': 602,
            'county': '嘉義縣',
            'city': '番路鄉'
        }, {
            'id': '603嘉義縣梅山鄉',
            'zip_code': 603,
            'county': '嘉義縣',
            'city': '梅山鄉'
        }, {
            'id': '604嘉義縣竹崎鄉',
            'zip_code': 604,
            'county': '嘉義縣',
            'city': '竹崎鄉'
        }, {
            'id': '605嘉義縣阿里山鄉',
            'zip_code': 605,
            'county': '嘉義縣',
            'city': '阿里山鄉'
        }, {
            'id': '606嘉義縣中埔鄉',
            'zip_code': 606,
            'county': '嘉義縣',
            'city': '中埔鄉'
        }, {
            'id': '607嘉義縣大埔鄉',
            'zip_code': 607,
            'county': '嘉義縣',
            'city': '大埔鄉'
        }, {
            'id': '608嘉義縣水上鄉',
            'zip_code': 608,
            'county': '嘉義縣',
            'city': '水上鄉'
        }, {
            'id': '611嘉義縣鹿草鄉',
            'zip_code': 611,
            'county': '嘉義縣',
            'city': '鹿草鄉'
        }, {
            'id': '612嘉義縣太保市',
            'zip_code': 612,
            'county': '嘉義縣',
            'city': '太保市'
        }, {
            'id': '613嘉義縣朴子市',
            'zip_code': 613,
            'county': '嘉義縣',
            'city': '朴子市'
        }, {
            'id': '614嘉義縣東石鄉',
            'zip_code': 614,
            'county': '嘉義縣',
            'city': '東石鄉'
        }, {
            'id': '615嘉義縣六腳鄉',
            'zip_code': 615,
            'county': '嘉義縣',
            'city': '六腳鄉'
        }, {
            'id': '616嘉義縣新港鄉',
            'zip_code': 616,
            'county': '嘉義縣',
            'city': '新港鄉'
        }, {
            'id': '621嘉義縣民雄鄉',
            'zip_code': 621,
            'county': '嘉義縣',
            'city': '民雄鄉'
        }, {
            'id': '622嘉義縣大林鎮',
            'zip_code': 622,
            'county': '嘉義縣',
            'city': '大林鎮'
        }, {
            'id': '623嘉義縣溪口鄉',
            'zip_code': 623,
            'county': '嘉義縣',
            'city': '溪口鄉'
        }, {
            'id': '624嘉義縣義竹鄉',
            'zip_code': 624,
            'county': '嘉義縣',
            'city': '義竹鄉'
        }, {
            'id': '625嘉義縣布袋鎮',
            'zip_code': 625,
            'county': '嘉義縣',
            'city': '布袋鎮'
        }, {
            'id': '630雲林縣斗南鎮',
            'zip_code': 630,
            'county': '雲林縣',
            'city': '斗南鎮'
        }, {
            'id': '631雲林縣大埤鄉',
            'zip_code': 631,
            'county': '雲林縣',
            'city': '大埤鄉'
        }, {
            'id': '632雲林縣虎尾鎮',
            'zip_code': 632,
            'county': '雲林縣',
            'city': '虎尾鎮'
        }, {
            'id': '633雲林縣土庫鎮',
            'zip_code': 633,
            'county': '雲林縣',
            'city': '土庫鎮'
        }, {
            'id': '634雲林縣褒忠鄉',
            'zip_code': 634,
            'county': '雲林縣',
            'city': '褒忠鄉'
        }, {
            'id': '635雲林縣東勢鄉',
            'zip_code': 635,
            'county': '雲林縣',
            'city': '東勢鄉'
        }, {
            'id': '636雲林縣臺西鄉',
            'zip_code': 636,
            'county': '雲林縣',
            'city': '臺西鄉'
        }, {
            'id': '637雲林縣崙背鄉',
            'zip_code': 637,
            'county': '雲林縣',
            'city': '崙背鄉'
        }, {
            'id': '638雲林縣麥寮鄉',
            'zip_code': 638,
            'county': '雲林縣',
            'city': '麥寮鄉'
        }, {
            'id': '640雲林縣斗六市',
            'zip_code': 640,
            'county': '雲林縣',
            'city': '斗六市'
        }, {
            'id': '643雲林縣林內鄉',
            'zip_code': 643,
            'county': '雲林縣',
            'city': '林內鄉'
        }, {
            'id': '646雲林縣古坑鄉',
            'zip_code': 646,
            'county': '雲林縣',
            'city': '古坑鄉'
        }, {
            'id': '647雲林縣莿桐鄉',
            'zip_code': 647,
            'county': '雲林縣',
            'city': '莿桐鄉'
        }, {
            'id': '648雲林縣西螺鎮',
            'zip_code': 648,
            'county': '雲林縣',
            'city': '西螺鎮'
        }, {
            'id': '649雲林縣二崙鄉',
            'zip_code': 649,
            'county': '雲林縣',
            'city': '二崙鄉'
        }, {
            'id': '651雲林縣北港鎮',
            'zip_code': 651,
            'county': '雲林縣',
            'city': '北港鎮'
        }, {
            'id': '652雲林縣水林鄉',
            'zip_code': 652,
            'county': '雲林縣',
            'city': '水林鄉'
        }, {
            'id': '653雲林縣口湖鄉',
            'zip_code': 653,
            'county': '雲林縣',
            'city': '口湖鄉'
        }, {
            'id': '654雲林縣四湖鄉',
            'zip_code': 654,
            'county': '雲林縣',
            'city': '四湖鄉'
        }, {
            'id': '655雲林縣元長鄉',
            'zip_code': 655,
            'county': '雲林縣',
            'city': '元長鄉'
        }, {
            'id': '700臺南市中西區',
            'zip_code': 700,
            'county': '臺南市',
            'city': '中西區'
        }, {
            'id': '701臺南市東區',
            'zip_code': 701,
            'county': '臺南市',
            'city': '東區'
        }, {
            'id': '702臺南市南區',
            'zip_code': 702,
            'county': '臺南市',
            'city': '南區'
        }, {
            'id': '704臺南市北區',
            'zip_code': 704,
            'county': '臺南市',
            'city': '北區'
        }, {
            'id': '708臺南市安平區',
            'zip_code': 708,
            'county': '臺南市',
            'city': '安平區'
        }, {
            'id': '709臺南市安南區',
            'zip_code': 709,
            'county': '臺南市',
            'city': '安南區'
        }, {
            'id': '710臺南市永康區',
            'zip_code': 710,
            'county': '臺南市',
            'city': '永康區'
        }, {
            'id': '711臺南市歸仁區',
            'zip_code': 711,
            'county': '臺南市',
            'city': '歸仁區'
        }, {
            'id': '712臺南市新化區',
            'zip_code': 712,
            'county': '臺南市',
            'city': '新化區'
        }, {
            'id': '713臺南市左鎮區',
            'zip_code': 713,
            'county': '臺南市',
            'city': '左鎮區'
        }, {
            'id': '714臺南市玉井區',
            'zip_code': 714,
            'county': '臺南市',
            'city': '玉井區'
        }, {
            'id': '715臺南市楠西區',
            'zip_code': 715,
            'county': '臺南市',
            'city': '楠西區'
        }, {
            'id': '716臺南市南化區',
            'zip_code': 716,
            'county': '臺南市',
            'city': '南化區'
        }, {
            'id': '717臺南市仁德區',
            'zip_code': 717,
            'county': '臺南市',
            'city': '仁德區'
        }, {
            'id': '718臺南市關廟區',
            'zip_code': 718,
            'county': '臺南市',
            'city': '關廟區'
        }, {
            'id': '719臺南市龍崎區',
            'zip_code': 719,
            'county': '臺南市',
            'city': '龍崎區'
        }, {
            'id': '720臺南市官田區',
            'zip_code': 720,
            'county': '臺南市',
            'city': '官田區'
        }, {
            'id': '721臺南市麻豆區',
            'zip_code': 721,
            'county': '臺南市',
            'city': '麻豆區'
        }, {
            'id': '722臺南市佳里區',
            'zip_code': 722,
            'county': '臺南市',
            'city': '佳里區'
        }, {
            'id': '723臺南市西港區',
            'zip_code': 723,
            'county': '臺南市',
            'city': '西港區'
        }, {
            'id': '724臺南市七股區',
            'zip_code': 724,
            'county': '臺南市',
            'city': '七股區'
        }, {
            'id': '725臺南市將軍區',
            'zip_code': 725,
            'county': '臺南市',
            'city': '將軍區'
        }, {
            'id': '726臺南市學甲區',
            'zip_code': 726,
            'county': '臺南市',
            'city': '學甲區'
        }, {
            'id': '727臺南市北門區',
            'zip_code': 727,
            'county': '臺南市',
            'city': '北門區'
        }, {
            'id': '730臺南市新營區',
            'zip_code': 730,
            'county': '臺南市',
            'city': '新營區'
        }, {
            'id': '731臺南市後壁區',
            'zip_code': 731,
            'county': '臺南市',
            'city': '後壁區'
        }, {
            'id': '732臺南市白河區',
            'zip_code': 732,
            'county': '臺南市',
            'city': '白河區'
        }, {
            'id': '733臺南市東山區',
            'zip_code': 733,
            'county': '臺南市',
            'city': '東山區'
        }, {
            'id': '734臺南市六甲區',
            'zip_code': 734,
            'county': '臺南市',
            'city': '六甲區'
        }, {
            'id': '735臺南市下營區',
            'zip_code': 735,
            'county': '臺南市',
            'city': '下營區'
        }, {
            'id': '736臺南市柳營區',
            'zip_code': 736,
            'county': '臺南市',
            'city': '柳營區'
        }, {
            'id': '737臺南市鹽水區',
            'zip_code': 737,
            'county': '臺南市',
            'city': '鹽水區'
        }, {
            'id': '741臺南市善化區',
            'zip_code': 741,
            'county': '臺南市',
            'city': '善化區'
        }, {
            'id': '742臺南市大內區',
            'zip_code': 742,
            'county': '臺南市',
            'city': '大內區'
        }, {
            'id': '743臺南市山上區',
            'zip_code': 743,
            'county': '臺南市',
            'city': '山上區'
        }, {
            'id': '744臺南市新市區',
            'zip_code': 744,
            'county': '臺南市',
            'city': '新市區'
        }, {
            'id': '745臺南市安定區',
            'zip_code': 745,
            'county': '臺南市',
            'city': '安定區'
        }, {
            'id': '800高雄市新興區',
            'zip_code': 800,
            'county': '高雄市',
            'city': '新興區'
        }, {
            'id': '801高雄市前金區',
            'zip_code': 801,
            'county': '高雄市',
            'city': '前金區'
        }, {
            'id': '802高雄市苓雅區',
            'zip_code': 802,
            'county': '高雄市',
            'city': '苓雅區'
        }, {
            'id': '803高雄市鹽埕區',
            'zip_code': 803,
            'county': '高雄市',
            'city': '鹽埕區'
        }, {
            'id': '804高雄市鼓山區',
            'zip_code': 804,
            'county': '高雄市',
            'city': '鼓山區'
        }, {
            'id': '805高雄市旗津區',
            'zip_code': 805,
            'county': '高雄市',
            'city': '旗津區'
        }, {
            'id': '806高雄市前鎮區',
            'zip_code': 806,
            'county': '高雄市',
            'city': '前鎮區'
        }, {
            'id': '807高雄市三民區',
            'zip_code': 807,
            'county': '高雄市',
            'city': '三民區'
        }, {
            'id': '811高雄市楠梓區',
            'zip_code': 811,
            'county': '高雄市',
            'city': '楠梓區'
        }, {
            'id': '812高雄市小港區',
            'zip_code': 812,
            'county': '高雄市',
            'city': '小港區'
        }, {
            'id': '813高雄市左營區',
            'zip_code': 813,
            'county': '高雄市',
            'city': '左營區'
        }, {
            'id': '814高雄市仁武區',
            'zip_code': 814,
            'county': '高雄市',
            'city': '仁武區'
        }, {
            'id': '815高雄市大社區',
            'zip_code': 815,
            'county': '高雄市',
            'city': '大社區'
        }, {
            'id': '820高雄市岡山區',
            'zip_code': 820,
            'county': '高雄市',
            'city': '岡山區'
        }, {
            'id': '821高雄市路竹區',
            'zip_code': 821,
            'county': '高雄市',
            'city': '路竹區'
        }, {
            'id': '822高雄市阿蓮區',
            'zip_code': 822,
            'county': '高雄市',
            'city': '阿蓮區'
        }, {
            'id': '823高雄市田寮區',
            'zip_code': 823,
            'county': '高雄市',
            'city': '田寮區'
        }, {
            'id': '824高雄市燕巢區',
            'zip_code': 824,
            'county': '高雄市',
            'city': '燕巢區'
        }, {
            'id': '825高雄市橋頭區',
            'zip_code': 825,
            'county': '高雄市',
            'city': '橋頭區'
        }, {
            'id': '826高雄市梓官區',
            'zip_code': 826,
            'county': '高雄市',
            'city': '梓官區'
        }, {
            'id': '827高雄市彌陀區',
            'zip_code': 827,
            'county': '高雄市',
            'city': '彌陀區'
        }, {
            'id': '828高雄市永安區',
            'zip_code': 828,
            'county': '高雄市',
            'city': '永安區'
        }, {
            'id': '829高雄市湖內區',
            'zip_code': 829,
            'county': '高雄市',
            'city': '湖內區'
        }, {
            'id': '830高雄市鳳山區',
            'zip_code': 830,
            'county': '高雄市',
            'city': '鳳山區'
        }, {
            'id': '831高雄市大寮區',
            'zip_code': 831,
            'county': '高雄市',
            'city': '大寮區'
        }, {
            'id': '832高雄市林園區',
            'zip_code': 832,
            'county': '高雄市',
            'city': '林園區'
        }, {
            'id': '833高雄市鳥松區',
            'zip_code': 833,
            'county': '高雄市',
            'city': '鳥松區'
        }, {
            'id': '840高雄市大樹區',
            'zip_code': 840,
            'county': '高雄市',
            'city': '大樹區'
        }, {
            'id': '842高雄市旗山區',
            'zip_code': 842,
            'county': '高雄市',
            'city': '旗山區'
        }, {
            'id': '843高雄市美濃區',
            'zip_code': 843,
            'county': '高雄市',
            'city': '美濃區'
        }, {
            'id': '844高雄市六龜區',
            'zip_code': 844,
            'county': '高雄市',
            'city': '六龜區'
        }, {
            'id': '845高雄市內門區',
            'zip_code': 845,
            'county': '高雄市',
            'city': '內門區'
        }, {
            'id': '846高雄市杉林區',
            'zip_code': 846,
            'county': '高雄市',
            'city': '杉林區'
        }, {
            'id': '847高雄市甲仙區',
            'zip_code': 847,
            'county': '高雄市',
            'city': '甲仙區'
        }, {
            'id': '848高雄市桃源區',
            'zip_code': 848,
            'county': '高雄市',
            'city': '桃源區'
        }, {
            'id': '849高雄市那瑪夏區',
            'zip_code': 849,
            'county': '高雄市',
            'city': '那瑪夏區'
        }, {
            'id': '851高雄市茂林區',
            'zip_code': 851,
            'county': '高雄市',
            'city': '茂林區'
        }, {
            'id': '852高雄市茄萣區',
            'zip_code': 852,
            'county': '高雄市',
            'city': '茄萣區'
        }, {
            'id': '817高雄市東沙群島',
            'zip_code': 817,
            'county': '高雄市',
            'city': '東沙群島'
        }, {
            'id': '819高雄市南沙群島',
            'zip_code': 819,
            'county': '高雄市',
            'city': '南沙群島'
        }, {
            'id': '880澎湖縣馬公市',
            'zip_code': 880,
            'county': '澎湖縣',
            'city': '馬公市'
        }, {
            'id': '881澎湖縣西嶼鄉',
            'zip_code': 881,
            'county': '澎湖縣',
            'city': '西嶼鄉'
        }, {
            'id': '882澎湖縣望安鄉',
            'zip_code': 882,
            'county': '澎湖縣',
            'city': '望安鄉'
        }, {
            'id': '883澎湖縣七美鄉',
            'zip_code': 883,
            'county': '澎湖縣',
            'city': '七美鄉'
        }, {
            'id': '884澎湖縣白沙鄉',
            'zip_code': 884,
            'county': '澎湖縣',
            'city': '白沙鄉'
        }, {
            'id': '885澎湖縣湖西鄉',
            'zip_code': 885,
            'county': '澎湖縣',
            'city': '湖西鄉'
        }, {
            'id': '900屏東縣屏東市',
            'zip_code': 900,
            'county': '屏東縣',
            'city': '屏東市'
        }, {
            'id': '901屏東縣三地門鄉',
            'zip_code': 901,
            'county': '屏東縣',
            'city': '三地門鄉'
        }, {
            'id': '902屏東縣霧臺鄉',
            'zip_code': 902,
            'county': '屏東縣',
            'city': '霧臺鄉'
        }, {
            'id': '903屏東縣瑪家鄉',
            'zip_code': 903,
            'county': '屏東縣',
            'city': '瑪家鄉'
        }, {
            'id': '904屏東縣九如鄉',
            'zip_code': 904,
            'county': '屏東縣',
            'city': '九如鄉'
        }, {
            'id': '905屏東縣里港鄉',
            'zip_code': 905,
            'county': '屏東縣',
            'city': '里港鄉'
        }, {
            'id': '906屏東縣高樹鄉',
            'zip_code': 906,
            'county': '屏東縣',
            'city': '高樹鄉'
        }, {
            'id': '907屏東縣鹽埔鄉',
            'zip_code': 907,
            'county': '屏東縣',
            'city': '鹽埔鄉'
        }, {
            'id': '908屏東縣長治鄉',
            'zip_code': 908,
            'county': '屏東縣',
            'city': '長治鄉'
        }, {
            'id': '909屏東縣麟洛鄉',
            'zip_code': 909,
            'county': '屏東縣',
            'city': '麟洛鄉'
        }, {
            'id': '911屏東縣竹田鄉',
            'zip_code': 911,
            'county': '屏東縣',
            'city': '竹田鄉'
        }, {
            'id': '912屏東縣內埔鄉',
            'zip_code': 912,
            'county': '屏東縣',
            'city': '內埔鄉'
        }, {
            'id': '913屏東縣萬丹鄉',
            'zip_code': 913,
            'county': '屏東縣',
            'city': '萬丹鄉'
        }, {
            'id': '920屏東縣潮州鎮',
            'zip_code': 920,
            'county': '屏東縣',
            'city': '潮州鎮'
        }, {
            'id': '921屏東縣泰武鄉',
            'zip_code': 921,
            'county': '屏東縣',
            'city': '泰武鄉'
        }, {
            'id': '922屏東縣來義鄉',
            'zip_code': 922,
            'county': '屏東縣',
            'city': '來義鄉'
        }, {
            'id': '923屏東縣萬巒鄉',
            'zip_code': 923,
            'county': '屏東縣',
            'city': '萬巒鄉'
        }, {
            'id': '924屏東縣崁頂鄉',
            'zip_code': 924,
            'county': '屏東縣',
            'city': '崁頂鄉'
        }, {
            'id': '925屏東縣新埤鄉',
            'zip_code': 925,
            'county': '屏東縣',
            'city': '新埤鄉'
        }, {
            'id': '926屏東縣南州鄉',
            'zip_code': 926,
            'county': '屏東縣',
            'city': '南州鄉'
        }, {
            'id': '927屏東縣林邊鄉',
            'zip_code': 927,
            'county': '屏東縣',
            'city': '林邊鄉'
        }, {
            'id': '928屏東縣東港鎮',
            'zip_code': 928,
            'county': '屏東縣',
            'city': '東港鎮'
        }, {
            'id': '929屏東縣琉球鄉',
            'zip_code': 929,
            'county': '屏東縣',
            'city': '琉球鄉'
        }, {
            'id': '931屏東縣佳冬鄉',
            'zip_code': 931,
            'county': '屏東縣',
            'city': '佳冬鄉'
        }, {
            'id': '932屏東縣新園鄉',
            'zip_code': 932,
            'county': '屏東縣',
            'city': '新園鄉'
        }, {
            'id': '940屏東縣枋寮鄉',
            'zip_code': 940,
            'county': '屏東縣',
            'city': '枋寮鄉'
        }, {
            'id': '941屏東縣枋山鄉',
            'zip_code': 941,
            'county': '屏東縣',
            'city': '枋山鄉'
        }, {
            'id': '942屏東縣春日鄉',
            'zip_code': 942,
            'county': '屏東縣',
            'city': '春日鄉'
        }, {
            'id': '943屏東縣獅子鄉',
            'zip_code': 943,
            'county': '屏東縣',
            'city': '獅子鄉'
        }, {
            'id': '944屏東縣車城鄉',
            'zip_code': 944,
            'county': '屏東縣',
            'city': '車城鄉'
        }, {
            'id': '945屏東縣牡丹鄉',
            'zip_code': 945,
            'county': '屏東縣',
            'city': '牡丹鄉'
        }, {
            'id': '946屏東縣恆春鎮',
            'zip_code': 946,
            'county': '屏東縣',
            'city': '恆春鎮'
        }, {
            'id': '947屏東縣滿州鄉',
            'zip_code': 947,
            'county': '屏東縣',
            'city': '滿州鄉'
        }, {
            'id': '950臺東縣臺東市',
            'zip_code': 950,
            'county': '臺東縣',
            'city': '臺東市'
        }, {
            'id': '951臺東縣綠島鄉',
            'zip_code': 951,
            'county': '臺東縣',
            'city': '綠島鄉'
        }, {
            'id': '952臺東縣蘭嶼鄉',
            'zip_code': 952,
            'county': '臺東縣',
            'city': '蘭嶼鄉'
        }, {
            'id': '953臺東縣延平鄉',
            'zip_code': 953,
            'county': '臺東縣',
            'city': '延平鄉'
        }, {
            'id': '954臺東縣卑南鄉',
            'zip_code': 954,
            'county': '臺東縣',
            'city': '卑南鄉'
        }, {
            'id': '955臺東縣鹿野鄉',
            'zip_code': 955,
            'county': '臺東縣',
            'city': '鹿野鄉'
        }, {
            'id': '956臺東縣關山鎮',
            'zip_code': 956,
            'county': '臺東縣',
            'city': '關山鎮'
        }, {
            'id': '957臺東縣海端鄉',
            'zip_code': 957,
            'county': '臺東縣',
            'city': '海端鄉'
        }, {
            'id': '958臺東縣池上鄉',
            'zip_code': 958,
            'county': '臺東縣',
            'city': '池上鄉'
        }, {
            'id': '959臺東縣東河鄉',
            'zip_code': 959,
            'county': '臺東縣',
            'city': '東河鄉'
        }, {
            'id': '961臺東縣成功鎮',
            'zip_code': 961,
            'county': '臺東縣',
            'city': '成功鎮'
        }, {
            'id': '962臺東縣長濱鄉',
            'zip_code': 962,
            'county': '臺東縣',
            'city': '長濱鄉'
        }, {
            'id': '963臺東縣太麻里鄉',
            'zip_code': 963,
            'county': '臺東縣',
            'city': '太麻里鄉'
        }, {
            'id': '964臺東縣金峰鄉',
            'zip_code': 964,
            'county': '臺東縣',
            'city': '金峰鄉'
        }, {
            'id': '965臺東縣大武鄉',
            'zip_code': 965,
            'county': '臺東縣',
            'city': '大武鄉'
        }, {
            'id': '966臺東縣達仁鄉',
            'zip_code': 966,
            'county': '臺東縣',
            'city': '達仁鄉'
        }, {
            'id': '970花蓮縣花蓮市',
            'zip_code': 970,
            'county': '花蓮縣',
            'city': '花蓮市'
        }, {
            'id': '971花蓮縣新城鄉',
            'zip_code': 971,
            'county': '花蓮縣',
            'city': '新城鄉'
        }, {
            'id': '972花蓮縣秀林鄉',
            'zip_code': 972,
            'county': '花蓮縣',
            'city': '秀林鄉'
        }, {
            'id': '973花蓮縣吉安鄉',
            'zip_code': 973,
            'county': '花蓮縣',
            'city': '吉安鄉'
        }, {
            'id': '974花蓮縣壽豐鄉',
            'zip_code': 974,
            'county': '花蓮縣',
            'city': '壽豐鄉'
        }, {
            'id': '975花蓮縣鳳林鎮',
            'zip_code': 975,
            'county': '花蓮縣',
            'city': '鳳林鎮'
        }, {
            'id': '976花蓮縣光復鄉',
            'zip_code': 976,
            'county': '花蓮縣',
            'city': '光復鄉'
        }, {
            'id': '977花蓮縣豐濱鄉',
            'zip_code': 977,
            'county': '花蓮縣',
            'city': '豐濱鄉'
        }, {
            'id': '978花蓮縣瑞穗鄉',
            'zip_code': 978,
            'county': '花蓮縣',
            'city': '瑞穗鄉'
        }, {
            'id': '979花蓮縣萬榮鄉',
            'zip_code': 979,
            'county': '花蓮縣',
            'city': '萬榮鄉'
        }, {
            'id': '981花蓮縣玉里鎮',
            'zip_code': 981,
            'county': '花蓮縣',
            'city': '玉里鎮'
        }, {
            'id': '982花蓮縣卓溪鄉',
            'zip_code': 982,
            'county': '花蓮縣',
            'city': '卓溪鄉'
        }, {
            'id': '983花蓮縣富里鄉',
            'zip_code': 983,
            'county': '花蓮縣',
            'city': '富里鄉'
        }, {
            'id': '890金門縣金沙鎮',
            'zip_code': 890,
            'county': '金門縣',
            'city': '金沙鎮'
        }, {
            'id': '891金門縣金湖鎮',
            'zip_code': 891,
            'county': '金門縣',
            'city': '金湖鎮'
        }, {
            'id': '892金門縣金寧鄉',
            'zip_code': 892,
            'county': '金門縣',
            'city': '金寧鄉'
        }, {
            'id': '893金門縣金城鎮',
            'zip_code': 893,
            'county': '金門縣',
            'city': '金城鎮'
        }, {
            'id': '894金門縣烈嶼鄉',
            'zip_code': 894,
            'county': '金門縣',
            'city': '烈嶼鄉'
        }, {
            'id': '896金門縣烏坵鄉',
            'zip_code': 896,
            'county': '金門縣',
            'city': '烏坵鄉'
        }, {
            'id': '209連江縣南竿鄉',
            'zip_code': 209,
            'county': '連江縣',
            'city': '南竿鄉'
        }, {
            'id': '210連江縣北竿鄉',
            'zip_code': 210,
            'county': '連江縣',
            'city': '北竿鄉'
        }, {
            'id': '211連江縣莒光鄉',
            'zip_code': 211,
            'county': '連江縣',
            'city': '莒光鄉'
        }, {
            'id': '212連江縣東引鄉',
            'zip_code': 212,
            'county': '連江縣',
            'city': '東引鄉'
        }];




    }
}