export default {
    install: (app) => {
        app.config.globalProperties.$round = (x) => {
            return Number.parseFloat(x).toFixed(0)
        }
        app.config.globalProperties.$numberWithCommas = (x) => {
            return Number.parseFloat(x).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        app.config.globalProperties.$ignoreDecimalIfInteger = (number) => {
            number = parseFloat(number)
            const integer_part = parseInt(number)
            if (Number.isInteger(number)) {
                return integer_part
            } else {
                return number
            }
        }
        app.config.globalProperties.$round10 = (num, decimal) => {
            return Math.round((num + Number.EPSILON) * Math.pow(10,decimal)) / Math.pow(10,decimal);
        }
    }
}