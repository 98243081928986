import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import FinancialPlugin from "./plugins/FinancialPlugin";
import PropertiesPlugin from "./plugins/PropertiesPlugin";
import TimePlugin from "./plugins/TimePlugin";

axios.defaults.baseURL = 'https://nestor.com.tw'
// axios.defaults.baseURL = 'https://127.0.0.1:8000'
createApp(App).use(store).use(router).use(FinancialPlugin).use(PropertiesPlugin).use(TimePlugin).mount('#app')
