<template>
    <section class="home_section">
        <a class="order_state_block order_created" href='/order_management?latest_order_state=OC'>
            <div class="order_state">待付款</div>
            <div class="order_num">{{ num_of_all_order_states?.order_created }}</div>
        </a>
        <a class="order_state_block order_paid" href='/order_management?latest_order_state=PA'>
            <div class="order_state">已付款</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_paid }}</div>
        </a>
        <a class="order_state_block order_confirmed" href='/order_management?latest_order_state=CF'>
            <div class="order_state">已確認</div>
            <div class="order_num">{{
                num_of_all_order_states?.order_confirmed }}</div>
        </a>
        <a class="order_state_block order_picked" href='/order_management?latest_order_state=OP'>
            <div class="order_state">已揀貨</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_picked }}</div>
        </a>
        <a class="order_state_block order_being_shipped" href='/order_management?latest_order_state=BS'>
            <div class="order_state">已出貨</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_being_shipped }}</div>
        </a>
        <a class="order_state_block order_delivered" href='/order_management?latest_order_state=OD'>
            <div class="order_state">已送達</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_delivered }}</div>
        </a>
        <a class="order_state_block order_being_refunded" href='/order_management?latest_order_state=BR'>
            <div class="order_state">退款中</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_being_refunded }}</div>
        </a>
        <a class="order_state_block order_being_returned"
        href='/order_management?latest_order_state=RT'>
            <div class="order_state">回收中</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_being_returned }}</div>
        </a>
        <a class="order_state_block order_completed" href='/order_management?latest_order_state=CP'>
            <div class="order_state">已完成</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_completed }}</div>
        </a>
        <a class="order_state_block order_cancelled" href='/order_management?latest_order_state=CC'>
            <div class="order_state">已取消</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_cancelled }}</div>
        </a>
        <a class="order_state_block order_refunded" href='/order_management?latest_order_state=RF'>
            <div class="order_state">已退款</div>
            <div class="order_num">{{
            num_of_all_order_states?.order_refunded }}</div>
        </a>
        <a class="order_state_block order_payment_fail" href='/order_management?latest_order_state=PF'>
            <div class="order_state">付款失敗</div>
            <div class="order_num">{{
                num_of_all_order_states?.order_payment_fail }}</div>
        </a>
    </section>
</template>

<style lang="scss">
.home_section {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    display: flex;
    flex-wrap: wrap;
}

.order_created {
    background-color: #e74c3c;
}

.order_paid {
    background-color: #f1c40f;
}

.order_confirmed {
    background-color: #f1c40f;
}

.order_picked {
    background-color: #2ecc71;
}

.order_being_shipped {
    background-color: #2ecc71;
}

.order_delivered {
    background-color: #2ecc71;
}

.order_completed {
    background-color: #27ae60;
}

.order_cancelled {
    background-color: #7f8c8d;
}

.order_being_refunded {
    background-color: #2ecc71;
}

.order_refunded {
    background-color: #7f8c8d;
}

.order_payment_fail {
    background-color: #7f8c8d;
}

.order_being_returned {
    background-color: #2ecc71;
}

.order_state_block {
    width: 8rem;
    height: 5rem;
    display: block;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.7rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    text-decoration: none;
}

.order_state {
    color: #fff;
}

.order_num {
    color: #fff;
    font-size: 3rem;
}
</style>

<script>
import axios from 'axios'

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            num_of_all_order_states: {
                'order_created': '',
                'order_paid': '',
                'order_confirmed': '',
                'order_picked': '',
                'order_being_shipped': '',
                'order_delivered': '',
                'order_completed': '',
                'order_cancelled': '',
                'order_being_refunded': '',
                'order_refunded': '',
                'order_being_returned': ''
            }
        }
    },
    mounted() {
        this.getNumOfAllOrderStates()
    },
    methods: {
        async getNumOfAllOrderStates() {
            const num_of_all_order_states_api_url = '/get_num_of_all_order_states'
            await axios
                .get(num_of_all_order_states_api_url)
                .then((response) => {
                    this.num_of_all_order_states = response.data
                    console.log(response)
                    console.log(this.num_of_all_order_states)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    }
}
</script>
